export default {
  data() {
    return {
      school_id: '',
      school: null,
      schoolLoading: false,
    };
  },

  computed: {
    schoolAsArray() {
      return this.school ? [this.school] : [];
    },
  },

  methods: {
    getSchool() {
      if (this.school_id && !this.school) {
        this.schoolLoading = true;
        this.$store.dispatch('school/getSchoolById', this.school_id).then((school) => {
          this.school = school;
        }).catch(() => {}).finally(() => {
          this.schoolLoading = false;
        });
      }
    },
  },

  created() {
    this.$nextTick(() => { this.getSchool(); });
  },
};
